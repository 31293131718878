<template>
  <silo-property-form
    :edit="true"
    :modelValue="siloProperty"
    :hide-dialog-button="true"
  />
</template>

<script>
import SiloPropertyForm from "./SiloPropertyForm.vue";
import { mapGetters } from "vuex";
export default {
  name: "SiloPropertyCreate",

  components: {
    SiloPropertyForm,
  },

  data() {
    return {
      siloProperty: null,
    };
  },

  computed: {
    ...mapGetters("silos", ["getPropertyById"]),
  },

  created() {
    if (this.$route.params.id == undefined) this.$router.push("/properties");

    this.siloProperty = this.getPropertyById(this.$route.params.id);
  },
};
</script>